
import Modal from 'react-bootstrap/Modal';
const ConfirmationDialog = ({message, show, setShowDialog, deleteRecord, delId}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={()=>setShowDialog(false)}
        backdrop="static"
        keyboard={false}
      >
     
        <Modal.Body className='text-center pd-50'>
            {message}
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <button className='btn btn-danger w-100' onClick={()=>setShowDialog(false)}>
            No
          </button>
          <button className='btn btn-primary w-100' onClick={()=>deleteRecord(delId)}>Yes</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationDialog;