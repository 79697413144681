import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Alerts, GetProductCategory } from "../../../helpers";
import Select from 'react-select';
import MUIDataTable from "mui-datatables";
import ConfModal from "../../../components/cmp/ConfModal";
import UpdateVariation from "../../../components/variations/UpdateVariation";
import CreateVariationOption from "../../../components/variations/options/CreateVariationOption";
import { FaEye, FaEdit } from "react-icons/fa";
const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Categories",
        link: "/app/product/categories",
    },
    {
        id: 3,
        label: "Variations",
        link: "/app/product/category/variations",
    }
];

const CreateCategoryVariation = () => {
    const [categories, setCategories] = useState([]);
    const [variations, setVariations] = useState([]);
    const [groupedVariations, setGroupedVariations] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const resp = await GetProductCategory.createCategoryVariation(values);
        if (resp && resp.status) {
            getCategoryVariation();
            Alerts.alertMessage(resp.message, 'top-right', 'success');
            setSubmitting(false);
            resetForm();
        } else {
            Alerts.alertMessage(resp.message, 'top-right', 'error')
        }
    };

    const getVariations = async () =>{
        const resp = await GetProductCategory.getVariations();
        if (resp && resp.status) {
            const options = resp.variations.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setVariations(options);
        }
    }

  

    const getCategoryVariation = async () => {
        const resp = await GetProductCategory.getCategoryVariations();
        if (resp && resp.status) {
            console.log(resp);
        const grouped = resp.variations.reduce((acc, item) => {
        const existingCategory = acc.find(
          (group) => group.category_id === item.category_id
        );

        if (existingCategory) {
          existingCategory.children.push({
            id: item.variation?.id,
            name: item.variation?.name,
            input_type:item.variation?.input_type
          });
        } else {
          acc.push({
            category_id: item.category_id,
            title: item.category.title,
            children: [
              {
                id: item.variation?.id,
                name: item.variation?.name,
                input_type:item.variation?.input_type
              },
            ],
          });
        }
        return acc;
      }, []);
      console.log(grouped);

            setGroupedVariations(grouped);
            // setVariations(resp.variations);
        }
    }

    const getCategories = async () => {
        const resp = await GetProductCategory.getAllCategories();
        if (resp && resp.status) {
            const options = resp.data.filter(item => item.parent_id === null).map((item) => ({
                value: item.id,
                label: item.title,
            }));
            setCategories(options);
        }
    };

    useEffect(() => {
         getCategories();
         getVariations();
         getCategoryVariation();
    }, []);



    // const handleDelete = async (id) => {
    //     const resp = await GetProductCategory.deleteVariation(id);
    //     if (resp && resp.status) {
    //         getVariations();
    //         Alerts.alertMessage(resp.message, 'top-right', 'success');

    //     } else {
    //         Alerts.alertMessage('Something went wrong !', 'top-right', 'danger')
    //     }
    // }



    const options = {
        filter: false,
        download: false,
        print: false,
        selectableRows: false
    };


    const columns = [
        {
            name: 'SL'
        },
        {
            name: 'Category',
            sortable: true,
        },
        {
            name: 'Variations',
            sortable: true,
        },
        {
            name: 'Actions',
            sortable: false,
        },
    ];
    const getSingleCategoryVariations = async (id) =>{
        const resp = await GetProductCategory.getSingleCategoryVariations(id);
        if(resp && resp.status){
            setSelectedCategory(resp.category);
            setShow(true);
        }
    }

 
    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Create Category Variation 
                        </h2>
                        <Link to="/app/product/categories" className="btn btn-success">
                            <BiCategory /> Categories
                        </Link>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <Formik
                                        initialValues={{
                                            category_id: "",
                                            variation_id:''
                                        }}
                                        validate={(values) => {
                                            const errors = {};
                                            if (!values.category_id) {
                                                errors.category_id = "Category is required";
                                            }
                                            if (!values.variation_id) {
                                                errors.variation_id = "Variation name is required";
                                            }
                                            return errors;
                                        }}
                                        onSubmit={handleSubmit}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue,
                                            isSubmitting,
                                        }) => (
                                            <form onSubmit={handleSubmit}>

                                                <div className="form-group">
                                                    <label htmlFor="category_id">Category</label>
                                                    <Select
                                                        id="category_id"
                                                        name="category_id"
                                                        placeholder="Select a Category"
                                                        options={categories}
                                                        isClearable
                                                        onChange={(selectedOption) =>
                                                            setFieldValue("category_id", selectedOption ? selectedOption.value : null)
                                                        }
                                                        onBlur={() => handleBlur({ target: { name: "category_id" } })}
                                                    />
                                                    {errors.category_id && touched.category_id && (
                                                        <span className="help-error">{errors.category_id}</span>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="variation_id">Variations</label>
                                                    <Select
                                                        id="variation_id"
                                                        name="variation_id"
                                                        placeholder="Select a variation"
                                                        options={variations}
                                                        isClearable
                                                        onChange={(selectedOption) =>
                                                            setFieldValue("variation_id", selectedOption ? selectedOption.value : null)
                                                        }
                                                        onBlur={() => handleBlur({ target: { name: "variation_id" } })}
                                                    />
                                                    {errors.variation_id && touched.variation_id && (
                                                        <span className="help-error">{errors.variation_id}</span>
                                                    )}
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? "Submitting..." : "Submit"}
                                                </button>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                                <div className="col-md-8">
                                   <MUIDataTable
                                        title="Category Variations"
                                        data={groupedVariations.map((item, i) => {

                                            return [
                                                ++i,
                                                item.title,
                                                item.children.map((varItem) => varItem.name).join(", "),
                                                <div className={`actions`} style={{ width: "150px" }}>
                                                     <button className='btn btn-info btn-sm' onClick={() => getSingleCategoryVariations(item.category_id)}><FaEdit size={14} /></button>
                                                </div>
                                            ]
                                        })}
                                        columns={columns}
                                        options={options}
                                    />

                                    {
                                        show && <UpdateVariation show={show} setShow={setShow} category={selectedCategory} getSingleCategoryVariations={getSingleCategoryVariations} getCategoryVariation={getCategoryVariation} />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateCategoryVariation;
