import React, { useState } from 'react';

const MediaImageDropzone = ({setFieldValue, fieldName='media_file'}) => {
  const [file, setFile] = useState('');
  const [fileError, setFileError] = useState('');
  const [fileType, setFileType] = useState('');

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFile(files[0]);
  };

  const handleFileChange = (event) => {
    setFileError('');
    const files = event.target.files;
   
    handleFile(files[0]);
  };

  const handleFile = (selectedFile) => {
    if (!selectedFile) return;
    const allowedTypes = ['image/', 'video/', 'text/'];
    const fileType = selectedFile.type;
  
    if (!allowedTypes.some(type => fileType.startsWith(type))) {
      setFileError('Please select an image, video, or text file.');
      return;
    }
    const fileTypeValue = fileType.split('/')[0];
    setFileType(fileTypeValue);
    setFile(URL.createObjectURL(selectedFile));
    setFieldValue(fieldName, selectedFile);
    
  };


  const preventDefault = (event) => {
    event.preventDefault();
  };

  const clearImage = () =>{
    setFile(null);
    setFieldValue('product_image', '')
  }


  return (
    <>


    <div
      style={{
        width: '100%',
        height: '200px',
        border: '1.5px dashed #ccc',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        overflow: 'hidden'
      }}
      onDrop={handleDrop}
      onDragOver={preventDefault}
    >
     
      {file ? (<div className='uploaded-box'>
        <div className="overlayBox">
          <button onClick={clearImage}>Clear</button>
        </div>
        {
          fileType !== '' && fileType === 'video' ? <video width="640" height="360" controls>
          <source src={file} type="video/mp4"/>
        </video> : fileType === 'image' ? <img
          src={file}
          alt="Uploaded"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        /> :null
        }
      
      </div>
       
      ) : (
        <input
          type="file"
          // accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="fileInput"
        />
      )}
      {!file && (
        <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
          Drop image here or click to select
        </label>
      )}
    </div>
    { fileError !== '' ? <span className='help-error text-center d-block'>{fileError}</span> : null }
        </>
  );
};

export default MediaImageDropzone;
