import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { Formik } from "formik";
import { Alerts, GetProductCategory } from "../../../helpers";
import Select from 'react-select';
import MUIDataTable from "mui-datatables";
import ConfModal from "../../../components/cmp/ConfModal";
import UpdateVariation from "../../../components/variations/UpdateVariation1";
import CreateVariationOption from "../../../components/variations/options/CreateVariationOption";
import { Spinner } from "react-bootstrap";
const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Categories",
        link: "/app/product/categories",
    },
    {
        id: 3,
        label: "Variations",
        link: "/app/product/category/variations",
    }
];

const VariationOption = () => {
    const [variation, setVariation] = useState(null);
    const { id } = useParams();
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        // const resp = await GetProductCategory.createVariation(values);
        console.log(values);
        // if (resp && resp.status) {
        //     Alerts.alertMessage(resp.message, 'top-right', 'success');
        //     setSubmitting(false);
        //     resetForm();
        // } else {
        //     Alerts.alertMessage(resp.message, 'top-right', 'danger')
        // }
    };

    const getVariation = async (id) => {
        const resp = await GetProductCategory.getSingleVariation(id);
        
        if(resp && resp.status){
            setVariation(resp.variation);
        }
    }

    useEffect(() => {
        getVariation(id);
    }, [id]);



    const handleDelete = async (id) => {
        const resp = await GetProductCategory.deleteVariation(id);
        if (resp && resp.status) {
            Alerts.alertMessage(resp.message, 'top-right', 'success');

        } else {
            Alerts.alertMessage('Something went wrong !', 'top-right', 'danger')
        }
    }



    const options = {
        filter: false,
        download: false,
        print: false,
        selectableRows: false
    };


    const columns = [
        {
            name: 'SL'
        },
        {
            name: 'Value',
            sortable: true,
        },
        {
            name: 'Actions',
            sortable: false,
        },
    ];
    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Create Variation Option
                        </h2>
                        <Link to="/app/product/categories" className="btn btn-success">
                            <BiCategory /> Categories
                        </Link>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            {variation === null ? <Spinner/> :  <div className="row">
                                <div className="col-md-4">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            variation_id: variation.id,
                                            value: ''
                                        }}
                                        validate={(values) => {
                                            const errors = {};
                                            if (!values.value) {
                                                errors.value = "Variation value is required";
                                            }
                                            return errors;
                                        }}
                                        onSubmit={handleSubmit}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue,
                                            isSubmitting,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <h4>{variation.variation_category.title}</h4>
                                                    <h5>{variation.name}</h5>
                                                </div>
                                                <div className="form-group">
                                                    <label>Variation Value</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="value"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.value}
                                                    />
                                                    {errors.value && touched.value && (
                                                        <span className="help-error">{errors.value}</span>
                                                    )}
                                                </div>


                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? "Submitting..." : "Add Now"}
                                                </button>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                                <div className="col-md-8">
                                    <MUIDataTable
                                        title="Variations"
                                        data={variation.variation_options.map((item, i) => {

                                            return [
                                                ++i,
                                                item.value,
                                                <div className={`actions`} style={{ width: "150px" }}>
                                                </div>
                                            ]
                                        })}
                                        columns={columns}
                                        options={options}
                                    />

                                </div>
                            </div>}
                           
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VariationOption;
