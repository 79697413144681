import React, {useEffect, useState} from 'react'
import Breadcrumb from '../../../components/partials/Breadcrumb';
import { BiCategory } from 'react-icons/bi';
import MUIDataTable from 'mui-datatables';
import { GetSettings } from '../../../helpers';
const breadcrumbs = [
  {
    id: 1,
    label: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    label: "Currency",
    link: "/app/product/categories",
  }
];


const options = {
  filter: false,
  download:false,
  print:false,
  selectableRows: false
};

const columns = [
  {
    name: 'SL'
  },
  {
      name: 'Code',
      sortable: true,
    },
  {
    name: 'Symbol',
    sortable: true,
  },
  {
    name: 'Rate',
    sortable: true,
  },
  {
      name: 'isDefault',
      sortable: true,
    }
];

const CurrencySetup = () => {
  const [data, setData] = useState([]);

  const getCurrency = async () =>{
     const resp = await GetSettings.getCurrencies();
     if(resp && resp.status){
      setData(resp.currencies);
     }
  }

  useEffect(()=>{
     getCurrency();
  },[]);
  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="innerPageWrapper">
        <div className="container-fluid">
          <div className="page-title">
            <h2>
              <BiCategory /> Currency Settings
            </h2>
           
          </div>
          <div className="card">
            <div className="card-body">
            <MUIDataTable
                            title="Products"
                            data={data.map((item, i) => {

                                return [
                                    ++i,
                                    item.code,
                                    item.symbol,
                                    item.rate,
                                    item.is_default
                                ]
                            })}
                            columns={columns}
                            options={options}
                        />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CurrencySetup