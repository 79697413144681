import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';
import { FiEdit, FiEye } from 'react-icons/fi';
import { FaTimes } from "react-icons/fa";
import MediaLibraryPopup from '../../cmp/MediaLibraryPopup';
import { GetProducts, Alerts } from '../../../helpers';
import MUIDataTable from 'mui-datatables';
import { format } from 'date-fns';
import ConfModal from '../../cmp/ConfModal';
import MyModal from '../../cmp/MyModal';
const AddProductMedia = ({ product_id }) => {
    const [product_thumb, setProductThumb] = useState('');
    const [showMedia, setShowMedia] = useState(false);
    const [mediaData, setMediaData] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [showMediaModal, setShowMediaModal]  = useState(false);

    const handleView = (value) =>{
        setSelectedMedia(value);
        setShowMediaModal(true)
    } 
    const handleCloseView = ()=>{
        setSelectedMedia(null);
        setShowMediaModal(false);
    }

    const handleSelectImage = (image) => {
        setProductThumb(image);
        setShowMedia(false);
    }

    const getProductMedia = async (pro_id) => {
        let resp = await GetProducts.getProductGallery(pro_id);
        if (resp && resp.status) {
            setMediaData(resp.data);
        }
    };

    useEffect(() => {
        getProductMedia(product_id);
    }, [product_id]);


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        const data = {
            media_id: values.product_image
        }
        let resp = await GetProducts.createProductGallery(data, product_id);
        if (resp && resp.status) {
            Alerts.alertMessage(resp.message, "top-right", "success");
            getProductMedia(product_id);
        } else {
            Alerts.alertMessage(resp.message, "top-right", "error");
        }
        setProductThumb('');
        resetForm();
        setSubmitting(false);
    };

    const deleteRecord = async (id) => {
            let resp = await GetProducts.deleteProductGallery(id);
            if (resp && resp.status) {
                Alerts.alertMessage(resp.message, 'top-right', 'success');
                getProductMedia(product_id);
            } else {
                Alerts.alertMessage(resp.message, 'top-right', 'error');
            }
        }

    const options = {
        filter: false,
        download: false,
        print: false,
        selectableRows: false
    };
    
    const columns = [
        {
            name: 'SL'
        },
        {
            name: 'Media',
            sortable: true,
        },
        {
            name: 'Media Type',
            sortable: true,
        },
        {
            name: 'Create At',
            sortable: true,
        },
        {
            name: 'Actions',
            sortable: false,
        },
    ];

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-5'>
                <Formik
                initialValues={{
                    product_image: '',
                }}
                validate={(values) => {
                    const errors = {};
                    if (!values.product_image) {
                        errors.product_image = "Product image is required";
                    }

                    return errors;
                }}
                onSubmit={handleSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row">


                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Select your file</label>
                                    <div className="dropzone-box">
                                        {
                                            product_thumb !== '' &&  (
                                                <>
                                                 {
                                                    product_thumb.file_type === 'image' ?  <img src={product_thumb.path} alt="product_thumbnail" /> : product_thumb.file_type === 'video' ?  <img src={product_thumb.poster} alt="product_thumbnail" /> : product_thumb.file_type === 'text' && <img src={'/images/txt-file.png'} alt={''}  style={{width:100}}/>   
                                                 }
                                                </>
                                        )
                                        }
                                        {
                                            product_thumb !== '' ? <button type="button" className="floatBtn" onClick={() => setShowMedia(true)}><FiEdit size={15} /></button> : <>
                                                <p>Drop files to upload</p>
                                                <p>or</p>
                                                <button type="button" onClick={() => setShowMedia(true)} className="choose-btn">Choose file</button>
                                            </>
                                        }
                                    </div>


                                    {errors.product_image && touched.product_image && (
                                        <span className="help-error">
                                            {errors.product_image}
                                        </span>
                                    )}
                                </div>
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Uploading..." : "Upload Now"}
                                    </button>
                                </div>

                            </div>
                        </div>
                        {
                            showMedia && <MediaLibraryPopup show={showMedia} setShowMedia={setShowMedia} handleSelectImage={handleSelectImage} setFieldValue={setFieldValue} fieldName="product_image" />
                        }
                    </form>


                )}
            </Formik>
                </div>
                <div className='col-md-7'>
                <MUIDataTable
                title="Product Gallery"
                data={mediaData.map((item, i) => {
                    return [
                        ++i,
                        item.media_file.file_type === 'image' ? <img src={item.media_file.path} alt="product_thumbnail" style={{ width: 70 }}/> : item.media_file.file_type === 'video' ? <img src={item.media_file.poster} alt="product_thumbnail" style={{ width: 70 }}/> : item.media_file.file_type === 'text' && <img src={'/images/txt-file.png'} alt={''} style={{ width: 70 }} />,
                        item.media_file.file_type,
                        format(item.createdAt, 'dd/MM/yyyy'),
                        <div className={`actions`}>
                            <button className="btn btn-success btn-sm" onClick={()=>handleView(item)}><FiEye size={14} /></button>&nbsp;
                            <ConfModal delId={item.id} message={'Are you sure you want to delete this record.'} handleDelete={deleteRecord}/>
                        </div>
                    ]
                })}
                columns={columns}
                options={options}
            />
            {
                selectedMedia !== null && <MyModal show={showMediaModal} setAddNew={handleCloseView} title="">
                {
                    selectedMedia.media_file.file_type === 'image' ? <img src={selectedMedia.media_file.path} alt="product_thumbnail" style={{ width: '100%' }}/> : selectedMedia.media_file.file_type === 'video' ?
                      <video width={'100%'} controls={true}>
                       <source src={selectedMedia.media_file.path} type={selectedMedia.media_file.type}/>
                     </video>
                     : selectedMedia.media_file.file_type === 'text' && <img src={'/images/txt-file.png'} alt={''} style={{ width: '100%' }} />
                }
            </MyModal>
            }
            
                </div>
            </div>
        
           
            
        </div>

    )
}

export default AddProductMedia