// import { useState, useEffect } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import MediaImageDropzone from "./MediaImageDropzone";
// import { Formik } from "formik";
// import { GetMedia, Alerts } from "../../helpers";
// import { FaCheck } from "react-icons/fa";
// const MediaLibraryPopup = ({ show, setShowMedia, isMulti=false }) => {
//   const [isLoading, setLoading] = useState(false);
//   const [mediaFiles, setMediaFiles] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentSelected, setCurrentSelected]  = useState(null);
//   const getData = async (crt_page, isAdded=false) => {
//     console.log(crt_page);
//     console.log(isAdded);

//     let resp = await GetMedia.getAllMedia(crt_page);
//     if (resp && resp.status) {
//       if(crt_page === 1){
//           setMediaFiles(resp.data);
//         }else{
//             setMediaFiles(prevData => [...prevData, ...resp.data]);
//           }
     
//       // if(isAdded){
//       //   setCurrentSelected(resp.data[0].id);
//       // }
//       setCurrentPage(resp.currentPage);
//       setTotalPages(resp.totalPages);
//       setLoading(false);
//     }
//   }

//   useEffect(() => {
//     getData(currentPage);
//   }, [currentPage])


//   const handleSubmit = async (values, { setSubmitting, resetForm }) => {
//     setLoading(true);
//     var formData = new FormData();
//     formData.append('media_file', values.media_file);
//     let resp = await GetMedia.createMedia(formData);
//     if(resp && resp.status){
//       getData(currentPage, true);
//       Alerts.alertMessage(resp.message, "top-right", "success");
//     }else{
//       Alerts.alertMessage(resp.message, "top-right", "error");
//     }
//     resetForm();
//     setSubmitting(false);
//     }

// const handleLoadMore = () =>{
//   getData(currentPage+1);
// }


// const handleSelectImage = (image) =>{
//    setCurrentSelected(image.id);
// }
//   return (
//     <>
//       <Modal
//         show={show}
//         onHide={() => setShowMedia(false)}
//         size="lg"
//         backdrop="static"
//         keyboard={false}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter">
//             Media Library
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body className='pd-50' scrollable={true}>
//           {
//             isLoading ? <div className='loader'>
//               <img src='/images/loader.gif' alt='loader' />
//             </div> : <Tabs
//               defaultActiveKey="media"
//               transition={false}
//               id="noanim-tab-example"
//               className="mb-3 media-gallery-popup"
//             >
//               <Tab eventKey="media" title="Choose Form Media">

//                 <div className='media-library row scrollBox'>
//                   {
//                     mediaFiles.length > 0 && mediaFiles.map((item) => {
//                       return <div className='col-md-3 mb-20' key={item.id}>
//                           <div className={`media-item ${currentSelected === item.id ? 'active' : ''}`} onClick={()=>handleSelectImage(item)}>
//                             {
//                               currentSelected === item.id && <div className='checkedWrap'>
//                               <FaCheck />
//                              </div>
//                             }
                          
//                                <img src={item.path} alt={item.filename}/>
//                           </div>
//                       </div>
//                     })
//                   }
//                 </div>
//                 <div className='text-center mt-20'>
//                 {
//                   totalPages > currentPage  &&  <button onClick={handleLoadMore} className='loadmore'>Load More</button>
//                 }
//                 </div>
             
               
//               </Tab>
//               <Tab eventKey="browse" title="Browse">
//               <Formik
//                                 initialValues={{
//                                     media_file: ''
//                                 }}
//                                 validate={(values) => {
//                                     const errors = {};
//                                     if (!values.media_file) {
//                                         errors.media_file = "Media file is required";
//                                     }



//                                     return errors;
//                                 }}
//                                 onSubmit={handleSubmit}
//                             >
//                                 {({
//                                     values,
//                                     errors,
//                                     touched,
//                                     handleChange,
//                                     handleBlur,
//                                     handleSubmit,
//                                     isSubmitting,
//                                     setFieldValue, // Add setFieldValue to handle file input
//                                 }) => (
//                                     <form onSubmit={handleSubmit}>
//                                         <div className="form-group">
//                                             <MediaImageDropzone setFieldValue={setFieldValue} />
//                                             {errors.media_file && touched.media_file && (
//                                                 <div className="text-center">
//                                                 <span className="help-error ">
//                                                     {errors.media_file}
//                                                 </span>
//                                                 </div>
//                                             )}
//                                         </div>
//                                         <div className="text-center">
//                                             <button
//                                                 type="submit"
//                                                 className="btn btn-primary"
//                                                 disabled={isSubmitting}
//                                             >
//                                                 {isSubmitting ? "Uploading..." : "Upload File"}
//                                             </button>
//                                         </div>

//                                     </form>
//                                 )}
//                             </Formik>
//               </Tab>

//             </Tabs>

//           }
//         </Modal.Body>

//       </Modal>
//     </>
//   );
// }

// export default MediaLibraryPopup;

import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MediaImageDropzone from "./MediaImageDropzone";
import { Formik } from "formik";
import { GetMedia, Alerts } from "../../helpers";
import { FaCheck } from "react-icons/fa";

const MediaLibraryPopup = ({ show, setShowMedia, isMulti = false, handleSelectImage,setFieldValue, fieldName}) => {
  const [isLoading, setLoading] = useState(false);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentSelected, setCurrentSelected] = useState(null);

  const getData = async (crt_page, isAdded = false) => {
    setLoading(true);
    try {
      const resp = await GetMedia.getAllMedia(crt_page);
      if (resp && resp.status) {
        setMediaFiles(prevData => {
          if (crt_page === 1) {
            return resp.data;
          } else {
            return [...prevData, ...resp.data];
          }
        });
        setCurrentPage(resp.currentPage);
        setTotalPages(resp.totalPages);
      }
    } catch (error) {
      console.error("Error fetching media:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    try {
      // console.log(values);

      const formData = new FormData();
      formData.append('media_file', values.media_file);
      const resp = await GetMedia.createMedia(formData);
      if (resp && resp.status) {
        getData(currentPage, true);
        Alerts.alertMessage(resp.message, "top-right", "success");
      } else {
        Alerts.alertMessage(resp.message, "top-right", "error");
      }
    } catch (error) {
      console.error("Error uploading media:", error);
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  const handleLoadMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handleSelectImageFromMedia = (image) => {
    setCurrentSelected(image);
    setFieldValue(fieldName, image.id);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShowMedia(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Media Library
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pd-50' scrollable={true}>
          {isLoading ? (
            <div className='loader'>
              <img src='/images/loader.gif' alt='loader' />
            </div>
          ) : (
            <Tabs
              defaultActiveKey="media"
              transition={false}
              id="noanim-tab-example"
              className="mb-3 media-gallery-popup"
            >
              <Tab eventKey="media" title="Choose From Media">
                <div className='media-library row scrollBox'>
                  {mediaFiles.length > 0 && mediaFiles.map((item) => (
                       item.file_type === 'image' ? 
                    <div className='col-md-3 mb-20' key={item.id}>
                      <div className={`media-item ${currentSelected && currentSelected.id === item.id ? 'active' : ''}`} onClick={() => handleSelectImageFromMedia(item)}>
                        {currentSelected && currentSelected.id === item.id && (
                          <div className='checkedWrap'>
                            <FaCheck />
                          </div>
                        )}
                        <img src={item.path} alt={item.filename} />
                      </div>
                    </div> : item.file_type === 'video' ?
                     <div className='col-md-3 mb-20' key={item.id}>
                     <div className={`media-item ${currentSelected && currentSelected.id === item.id ? 'active' : ''}`} onClick={() => handleSelectImageFromMedia(item)}>
                       {currentSelected && currentSelected.id === item.id && (
                         <div className='checkedWrap'>
                           <FaCheck />
                         </div>
                       )}
                       <video width="100%">
                          <source src={item.path} type="video/mp4"/>
                       </video>
                     </div>
                   </div>
                    : null
                  ))}
                </div>
                <div className='popup-action'>
                {totalPages > currentPage && (
                  <div className='text-center mt-20'>
                    <button onClick={handleLoadMore} className='load_more'>Load More</button>
                  </div>
                )}
                 {currentSelected && (
                  <div className='text-center mt-20'>
                    <button type='button' className='load_more' onClick={() => handleSelectImage(currentSelected)}>Insert Image</button>
                  </div>
                )}
                </div>
               
              </Tab>
              <Tab eventKey="browse" title="Browse">
                <Formik
                  initialValues={{
                    media_file: ''
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.media_file) {
                      errors.media_file = "Media file is required";
                    }
                    return errors;
                  }}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue, // Add setFieldValue to handle file input
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <MediaImageDropzone setFieldValue={setFieldValue} />
                        {errors.media_file && touched.media_file && (
                          <div className="text-center">
                            <span className="help-error ">
                              {errors.media_file}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Uploading..." : "Upload File"}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </Tab>
            </Tabs>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MediaLibraryPopup;
