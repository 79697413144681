import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { Alerts, GetProductCategory, GetProducts } from "../../../helpers";
import { MdWarning } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from "yup";
const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Product",
        link: "/app/products",
    },
    {
        id: 3,
        label: "Variation",
        link: "/app/product/categories/create",
    },
];

const ProductVariation = () => {
    const { product_id } = useParams();
    const [variations, setVariations] = useState([]);
    const [visibleInputs, setVisibleInputs] = useState({});

    const getVariations = async (id) => {
        const resp = await GetProductCategory.getProductVariations(id);
        if (resp && resp.status) {
            setVariations(resp.variations);

            const initialVisibility = resp.variations.reduce((acc, variation) => {
                acc[variation.name] = false;
                return acc;
            }, {});
            setVisibleInputs(initialVisibility);

            const initialValues = resp.variations.reduce((acc, variation) => {
                acc[variation.slug] = ""; 
                return acc;
            }, { quantity: "", price:"" });
            formik.setValues(initialValues);
        }
    };

    const toggleInputVisibility = (name) => {
        setVisibleInputs((prev) => ({
            ...prev,
            [name]: !prev[name],
        }));
    };

    useEffect(() => {
        getVariations(product_id);
    }, [product_id]);

    const generateValidationSchema = () => {
        let schema = {
            quantity: Yup.number()
                .typeError("Quantity must be a number")
                .min(1, "Quantity must be at least 1")
                .required("Quantity is required"),
            price: Yup.number()
                .typeError("Price must be a number")
                .min(1, "Price must be at least 1")
                .required("Price is required"),
        };

        variations.forEach((variation) => {
            if (visibleInputs[variation.name]) {
                schema[variation.slug] = Yup.string().required(`${variation.name} is required`);
            }
        });

        return Yup.object(schema);
    };

    // Formik setup
    const formik = useFormik({
        initialValues: {
            quantity: "",
            price:""
        },
        validationSchema: generateValidationSchema(),
        onSubmit: async (values) => {
            const filteredValues = Object.keys(values).reduce((acc, key) => {
                if (values[key] && visibleInputs[key] !== false) {
                    acc[key] = values[key];
                }
                return acc;
            }, {});

            const { quantity, price, ...updatedObj } = filteredValues;
             const data = {
                productId:product_id,
                variations:updatedObj,
                quantity:quantity,
                price:price

             }
             const resp = await GetProducts.createProductVariations(data);
             if(resp && resp.status){
                Alerts.alertMessage(resp.message, 'top-right', 'success');
             }else{
                Alerts.alertMessage('Something went wrong.', 'top-right', 'error')
             }
        },
        enableReinitialize: true,
    });

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Create Product Variation
                        </h2>
                        <Link to="/app/products" className="btn btn-success">
                            <BiCategory /> Products
                        </Link>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="variationList">
                                    <h5>Variations</h5>
                                    <ul>
                                        {variations.length > 0 ? (
                                            variations.map((item, i) => {
                                                return (
                                                    <li key={i}>
                                                        <label htmlFor={`variation_${i}`}>
                                                            <input
                                                                type="checkbox"
                                                                name="variation"
                                                                id={`variation_${i}`}
                                                                onChange={() =>
                                                                    toggleInputVisibility(item.name)
                                                                }
                                                            />
                                                            {item.name}
                                                        </label>
                                                        {visibleInputs[item.name] && (
                                                            <div style={{ marginTop: 10, marginRight: 5 }}>
                                                                {item.input_type === "text" && (
                                                                    <input
                                                                        type="text"
                                                                        placeholder={`Enter ${item.name}`}
                                                                        className="form-control mr-3"
                                                                        id={item.name}
                                                                        name={item.slug}
                                                                        value={formik.values[item.slug]}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                )}
                                                                {item.input_type === "number" && (
                                                                    <input
                                                                        type="number"
                                                                        placeholder={`Enter ${item.name}`}
                                                                        className="form-control"
                                                                        id={item.name}
                                                                        name={item.slug}
                                                                        value={formik.values[item.slug]}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                )}
                                                                {item.input_type === "textarea" && (
                                                                    <textarea
                                                                        placeholder={`Enter ${item.name}`}
                                                                        className="form-control"
                                                                        rows="3"
                                                                        id={item.name}
                                                                        name={item.slug}
                                                                        value={formik.values[item.slug]}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    ></textarea>
                                                                )}
                                                                {formik.touched[item.slug] &&
                                                                    formik.errors[item.slug] && (
                                                                        <div
                                                                            style={{
                                                                                color: "red",
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            {formik.errors[item.slug]}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        )}
                                                    </li>
                                                );
                                            })
                                        ) : (
                                            <p
                                                style={{
                                                    color: "#881212",
                                                    fontSize: 14,
                                                    marginTop: 10,
                                                }}
                                            >
                                                <MdWarning size={18} /> Variation not found!
                                            </p>
                                        )}
                                    </ul>
                                </div>
                                {/* Quantity Field */}
                                <div className="row" style={{ marginTop: 20 }}>
                                <div className="col-md-6">
                                    <label htmlFor="quantity">Quantity</label>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name="quantity"
                                        className="form-control"
                                        placeholder="Enter quantity"
                                        value={formik.values.quantity}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.quantity && formik.errors.quantity ? (
                                        <div style={{ color: "red", fontSize: 12 }}>
                                            {formik.errors.quantity}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="price">Price</label>
                                    <input
                                        type="number"
                                        id="price"
                                        name="price"
                                        className="form-control"
                                        placeholder="Enter price"
                                        value={formik.values.price}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.price && formik.errors.price ? (
                                        <div style={{ color: "red", fontSize: 12 }}>
                                            {formik.errors.price}
                                        </div>
                                    ) : null}
                                </div>
                                </div>
                             
                                <button type="submit" className="btn btn-primary" style={{ marginTop: 20 }}>
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductVariation;


