import React from 'react'
import '../assets/css/error.css';
// import { useHistory } from 'react-router-dom';
const NotFound = () => {
    // const history = useHistory();
    // const goBack = () => {
    //     history.goBack();
    //   };
  return (
    <div className='error-wrapper'>
          <div className='error-inner-wrapper'>
              <h1>404</h1>
              <p>Page Not Found !</p>
              {/* <button className='t_primary_btn' onClick={goBack}>Go Back</button> */}
          </div>
    </div>
  )
}

export default NotFound