// components/Layouts/Layout.js
import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import Sidebar from '../partials/Sidebar';
import { FaEnvelope, FaBell, FaUser, FaUnlockAlt } from 'react-icons/fa';
import { FiMenu, FiChevronLeft } from 'react-icons/fi';
import '../../assets/css/layout.css'
import LogoutButton from '../LogoutButton';
const Layout = () => {
    const [toggleSidebar, setToggleSidebar] = useState(false)
    return (
        <div className='layoutWrapper'>
            <Sidebar toggleSidebar={toggleSidebar} />
            <div className={`layoutMain ${!toggleSidebar ? 'ml-256' : ''}`}>
                <header className='topHeader'>
                    <div className={`${toggleSidebar ? 'sidebarBtn ml-15' : 'sidebarBtn'}`}>
                        <button onClick={() => setToggleSidebar(!toggleSidebar)}>
                            {
                                toggleSidebar ? <FiMenu /> : <FiChevronLeft />
                            }
                        </button>
                    </div>
                    <ul className='topHeaderMenu'>
                        <li>
                            <Link to="#" className='ml-15'><FaBell /></Link>
                        </li>
                        <li>
                            <Link to="#"><FaEnvelope /></Link>
                        </li>

                        <li className="profile-menu">
                            <div className="c-avatar"><img className="c-avatar-img" src={'/images/avatar.jfif'} alt="user@email.com" /></div>
                            <ul className="profile-link-dropdown">
                                <li> <Link to="/app/profile"><FaUser /> Profile</Link></li>
                                <li> <Link to="/app/update-password"><FaUnlockAlt /> Update Password</Link></li>
                                <li> <LogoutButton /></li>
                            </ul>
                        </li>

                    </ul>
                </header>
                <main className='appMain'>
                    <Outlet />
                </main>
            </div>


            <footer>
                <p>© 2024 My App</p>
            </footer>
        </div>
    );
};

export default Layout;
