import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../../components/partials/Breadcrumb';
import { BiCategory } from "react-icons/bi";
import { Alerts, GetMedia } from '../../../helpers';
import { Link } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";
import ConfirmationDialog from '../../../components/cmp/ConfirmationDialog';

const breadcrumbs = [
  {
    id: 1,
    label: "Dashboard",
    link: "/",
  },
  {
    id: 2,
    label: "Media Library",
    link: "/app/media-library",
  },
];

const MediaLibrary = () => {
  const [isLoading, setLoading] = useState(true);
  const [mediaData, setMediaData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [singleMedia, setSingleMedia] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [delId, setDelId] = useState(null);


  const getData = async (crt_page) => {
    let resp = await GetMedia.getAllMedia(crt_page);
    if (resp && resp.status) {
      if(crt_page === 1){
        setMediaData(resp.data);
      }else{
        setMediaData(prevData => [...prevData, ...resp.data]);
      }
      setTotalPages(resp.totalPages);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData(currentPage);
  }, [currentPage])

  // const nextPage = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const prevPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  const handleItemClick = (item) => {
    setSingleMedia(item);
  }


  const deleteRecord = async (id) => {
    let resp = await GetMedia.deleteMedia(id);
    if (resp && resp.status) {
      setSingleMedia(null);
      getData();
      Alerts.alertMessage(resp.message, 'top-right', 'success');
      setTimeout(()=>{
        window.location.reload();
      }, 2000);
       
    } else {
      Alerts.alertMessage(resp.message, 'top-right', 'error');
    }
    setDelId(null);
    setShowDialog(false);
  }

  const showDialogBox = (id) => {
    setDelId(id);
    setShowDialog(true);
  }
const handleLoadMore = () =>{
  setCurrentPage(currentPage+1);
}
  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="innerPageWrapper">
        <div className='container-fluid'>
          <div className='page-title'>
            <h2><BiCategory /> Media Library</h2>
            <Link to="/app/media-library/create" className="btn btn-success"><FaPlus /> Add New</Link>
          </div>
          {
            isLoading ? <div className='loader'>
              <img src='/images/loader.gif' alt='loader' />
            </div> : <div className='media-library'>
              <div className='row'>
                <div className={`${singleMedia ? 'col-md-9' : 'col-md-12'}`}>
                  <div className='row'>

                    {
                      mediaData.length > 0 && mediaData.map((item) => {
                        return <div className='col-md-3 mb-20' key={item.id}>
                           <div className='media-item'>
                           <a href='#' onClick={() => handleItemClick(item)}>
                             {
                              item.file_type === 'image' &&  <img src={item.path} alt={item.filename} /> 
                             }
                             {
                              item.file_type === 'video' && <img src={item.poster} alt={item.filename}/> 
                             }
                             {
                              item.file_type === 'text' && <img src={'/images/txt-file.png'} alt={item.filename}  style={{width:100}}/> 
                             }
                             
                            </a>
                           </div>
                        </div>
                      })
                    }


                  </div>
                 
                   <div className='pagination'>
                    <button onClick={handleLoadMore} disabled={currentPage === totalPages} className={`load_more ${currentPage === totalPages ? 'dsb-btn' : ''}`}>
                      Load More
                    </button>
                    </div>

                </div>
                {
                  singleMedia ? <div className='col-md-3'>
                    <div className='media-sidebar'>
                      <h4>Media Details</h4>
                      <div className='media-description'>
                        <div className='mdImg'>
                             {
                              singleMedia.file_type === 'image' &&  <img src={singleMedia.path} alt={singleMedia.filename} /> 
                             }
                              {
                              singleMedia.file_type === 'video' &&  <video width="320" height="200" controls>
                              <source src={singleMedia.path} type="video/mp4"/>
                            </video>
                             }
                             {
                              singleMedia.file_type === 'text' && <img src={'/images/txt-file.png'} alt={singleMedia.filename}  style={{width:100}}/> 
                             }
                         
                        </div>
                        <div className='mdDetails'>
                          <div className='form-group'>
                            <label>File Path</label>
                            <input type='text' className='form-control' value={singleMedia.path} readOnly />
                          </div>
                          <div className='form-group'>
                            <label>Filename</label>
                            <input type='text' className='form-control' value={singleMedia.filename} readOnly />
                          </div>
                          <div className='form-group'>
                            <label>File Size</label>
                            <input type='text' className='form-control' value={singleMedia.size} readOnly />
                          </div>

                          <div className='form-group text-right'>
                            <button type='button' className='btn btn-danger' onClick={() => showDialogBox(singleMedia.id)}>Delete</button>
                          </div>

                        </div>
                      </div>
                    </div>
                    {showDialog ? <ConfirmationDialog delId={delId} setShowDialog={setShowDialog} deleteRecord={deleteRecord} show={showDialog} message="Are you sure you want to delete this record." /> : null}
                  </div> : null
                }

              </div>

            </div>
          }
        </div>
      </div>
    </>
  )
}

export default MediaLibrary;