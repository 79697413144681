import React from 'react'
// import FontAwesome from 'react-fontawesome'
import 'bootstrap-icons/font/bootstrap-icons.css';
const Icon = ({icon, customClass}) => {
  return (
    <>
       <i className={`bi ${icon} ${customClass}`}></i>
       {/* <FontAwesome
      className={customClass}
      name={icon}
    /> */}
    </>
   
  )
}

export default Icon