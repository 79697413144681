import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ConfModal from '../cmp/ConfModal';
import { GetProductCategory, Alerts } from '../../helpers';
const UpdateVariation = ({ show, setShow, category, getSingleCategoryVariations, getCategoryVariation }) => {
    const [isShowDel, setShowDel] = useState(false);


        const handleDelete = async (id) => {
        const resp = await GetProductCategory.deleteCategoryVariation(id);
        if (resp && resp.status) {
            setShowDel(false);
            getSingleCategoryVariations(category.id);
            getCategoryVariation();
            Alerts.alertMessage(resp.message, 'top-right', 'success');
        } else {
            Alerts.alertMessage('Something went wrong !', 'top-right', 'danger')
        }
    }
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{category.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Variation name</th>
                            <th>Input Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            category.category_variations.length > 0 && category.category_variations.map((varItem) => {
                                return <tr key={varItem.variation.id}>
                                    <td>{varItem.variation.name}</td>
                                    <td>{varItem.variation.input_type}</td>

                                    <td>
                                        <ConfModal delId={varItem.id} message="Are you sure you want to delete this record" handleDelete={handleDelete} isShow={isShowDel} setShow={setShowDel}/>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateVariation