// context/AuthContext.js
import React, { createContext, useState, useContext } from "react";
import { jwtDecode } from "jwt-decode";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("userToken"));

  const login = (data) => {
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('userData', JSON.stringify(data.userData));
    localStorage.setItem('userToken', data.accessToken);
    setToken(data.accessToken);
  };

  const logout = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("userData");
    localStorage.removeItem("userToken");
    setToken(null);
  };

  const isAuthenticated = () => {
    if (!token) return false;

    try {
        const decoded = jwtDecode(token);
        if (decoded.exp * 1000 < Date.now()) {
            console.log("Token expired");
            logout();
            return false;
          }
          return true;
    } catch {
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for easy access
export const useAuth = () => useContext(AuthContext);
