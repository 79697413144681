const API_URL = "https://apitriplast.articloo.com";
// const API_URL = 'http://localhost:3001'

const Apis = {
    UserLogin:`${API_URL}/api/auth/sign-in`,
    GetUserProfile:`${API_URL}/api/user/profile`,
    UpdateUserProfile:`${API_URL}/api/user/profile/update`,
    CheckUserCurrentPassword:`${API_URL}/api/user/check/password`,
    UpdateUserPassword:`${API_URL}/api/user/update/password`,
    GetAllUsers:`${API_URL}/api/user/all`,
    //Product Categories
    GetAllCategories:`${API_URL}/api/categories`,
    CreateCategory:`${API_URL}/api/category/create`,
    DeleteCategory:`${API_URL}/api/category/`,
    SingleCategory:`${API_URL}/api/category/`,
    UpdateCategory:`${API_URL}/api/category/`,
    GetCategoryFaqs:`${API_URL}/api/category/faq/`,
    CreateCategoryFaq:`${API_URL}/api/category/faq/create/`,
    DeleteCategoryFaq:`${API_URL}/api/category/faq/`,  
    UpdateCategoryFaq:`${API_URL}/api/category/faq/update/`,   
    CreateProduct:`${API_URL}/api/product/create`, 
    UpdateProduct:`${API_URL}/api/product/edit/`,  
    GetAllProducts:`${API_URL}/api/products`, 
    GetSingleProduct:`${API_URL}/api/products/`,   
    CreateProductGallery:`${API_URL}/api/product/gallery/`,  
    GetProductGallery:`${API_URL}/api/product/gallery/`,  
    ProductGalleryDelete:`${API_URL}/api/product/gallery/`,
    CreateProductCategoryVariation:`${API_URL}/api/category/variation/create`,  
    CreateVariation:`${API_URL}/api/variation/create`,
    UpdateVariationData:`${API_URL}/api/variation/update/`,  
    GetVariations:`${API_URL}/api/variation/all`, 
    CreateProductVariations:`${API_URL}/api/product/variations/create`,  
    GetCategoryVariations:`${API_URL}/api/category/variation/all`,  
    GetSingleVariation:`${API_URL}/api/category/variation/`,  
    DeleteVariation:`${API_URL}/api/variation/`,
    DeleteCategoryVariation:`${API_URL}/api/category/variation/`, 
    UpdateVariation:`${API_URL}/api/category/variation/`,  
    CreateVariationOption:`${API_URL}/api/category/variation/option/create`,  
    EditVariationOption:`${API_URL}/api/category/variation/option/`,  
    DeleteVariationOption:`${API_URL}/api/category/variation/option/`,  
    GetAllVariationOptions:`${API_URL}/api/category/variation/option/all`, 
    GetProductVariations:`${API_URL}/api/product/category/variations/`,
    GetCategoryWiseVariations:`${API_URL}/api/category/variations/`,  
    GetProductVariation:`${API_URL}/api/product/variation/`, 
    DeleteProductVariation:`${API_URL}/api/product/variation/delete/`,  

    CreateProductVariationGallery:`${API_URL}/api/product/variation/gallery/create`,  
    DeleteProductVariationGallery:`${API_URL}/api/product/variation/gallery/delete/`, 
    CreateProductVariationContent:`${API_URL}/api/product/variation/content/create`, 
    UpdateProductVariationContent:`${API_URL}/api/product/variation/content/update/`, 
    
    //Media Routes
    CreateMedia:`${API_URL}/api/media/create`, 
    CreateVideoMedia:`${API_URL}/api/media/video/create`, 
    
    GetAllMedia:`${API_URL}/api/media/all`, 
    DeleteMedia:`${API_URL}/api/media/`,  

    //Post Categories
    GetAllPostCategories:`${API_URL}/api/post/categories`,
    CreatePostCategory:`${API_URL}/api/post/category/create`,
    DeletePostCategory:`${API_URL}/api/post/category/`,
    SinglePostCategory:`${API_URL}/api/post/category/`,
    UpdatePostCategory:`${API_URL}/api/post/category/`,
    CreatePost:`${API_URL}/api/post/create`,
    GetAllPost:`${API_URL}/api/posts`,
    GetSinglePost:`${API_URL}/api/post/`,
    DeletePost:`${API_URL}/api/post/`,
    UpdatePost:`${API_URL}/api/post/`,
    UploadCkeditor:`${API_URL}/api/ckeditor/upload`,

    //Settings
    GetCurrencies:`${API_URL}/api/currencies`,
       
  };
export { API_URL, Apis };