import api from '../../ApiConfig';
import { Apis } from '../../config';
var token = localStorage.getItem('userToken');
const getAllPostCategories = async () => {
  try {
    let result = await api.get(Apis.GetAllPostCategories, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const createPostCategory = async (data) => {
  try {
    let result = await api.post(Apis.CreatePostCategory, data, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const updatePostCategory = async (data, id) => {
  try {
    let result = await api.put(Apis.UpdatePostCategory+id, data, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const deletePostCategory = async (id) => {
  try {
    let result = await api.delete(Apis.DeletePostCategory+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const getSinglePostCategory = async (id) => {
  try {
    let result = await api.get(Apis.SinglePostCategory+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

//Get Post 
const getAllPost = async () => {
  try {
    let result = await api.get(Apis.GetAllPost, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const getSinglePost = async (id) => {
  try {
    let result = await api.get(Apis.GetSinglePost+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const deletePost = async (id) => {
  try {
    let result = await api.delete(Apis.DeletePost+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const updatePost = async (data, id) => {
  try {
    let result = await api.put(Apis.UpdatePost+id, data, {
        headers: {
          'x-access-token': token,
          "Content-Type": "multipart/form-data",
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const createPost = async (data) => {
  try {
    let result = await api.post(Apis.CreatePost, data, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const exportedObject = {

    createPostCategory,
    getAllPostCategories,
    deletePostCategory,
    getSinglePostCategory,
    updatePostCategory,
    createPost,
    getAllPost,
    getSinglePost,
    deletePost,
    updatePost
};
export default exportedObject;