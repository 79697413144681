import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/partials/Breadcrumb";
import { BiCategory } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Field } from "formik";
import { GetProductCategory, Alerts, GetProducts } from "../../../helpers";
import CKEditorField from "../../../components/cmp/CKEditorField";
import Select from "react-select";

import { FiEdit } from "react-icons/fi";
import MediaLibraryPopup from "../../../components/cmp/MediaLibraryPopup";

const breadcrumbs = [
    {
        id: 1,
        label: "Dashboard",
        link: "/",
    },
    {
        id: 2,
        label: "Products",
        link: "/app/products",
    },
    {
        id: 3,
        label: "Edit Products",
        link: "#",
    },
];

const EditProduct = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [categoryOptions, setCategoriesOption] = useState([]);
    const [productData, setProductData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [showMedia, setShowMedia] = useState(false);
    const [product_thumb, setProductThumb] = useState('');

    const getEditProduct = async (product_id) =>{
        let resp = await GetProducts.getSingleProducts(product_id);
        if(resp && resp.status){
                setProductData(resp.data);
                setProductThumb(resp.data.product_thumbnail);
                setLoading(false);
        }else{
            Alerts.alertMessage(resp.message, 'top-right', 'error');
            navigate("/app/products");
        }
    }

    const getCategories = async () => {
        let resp = await GetProductCategory.getAllCategories();
        if (resp && resp.status) {
            const optionsValue = resp.data.map(item => ({
                value: item.id,
                label: item.title
            }));
            setCategoriesOption(optionsValue);
        }
    };

    useEffect(() => {
        getCategories();
        getEditProduct(id)
    }, [id]);

    const handleSubmit = async (values, { setSubmitting }) => {
        const data = {
            title:values.title,
            short_description:values.short_description,
            description:values.description,
            specification:values.specification,
            regular_price:values.regular_price,
            sale_price:values.sale_price,
            tax_status:values.tax_status,
            best_seller:values.best_seller ? 1 : 0,
            sku:values.sku,
            stock:values.stock,
            product_image:values.product_image,
            status:values.status,
            categories:values.categoriesac
        }

            let resp = await GetProducts.updateProduct(data, id);
            console.log(resp);
            // if (resp && resp.status) {
            //   Alerts.alertMessage(resp.message, "top-right", "success");
            // //  navigate("/app/products");
            // } else {
            //   Alerts.alertMessage(resp.message, "top-right", "error");
            // }
            // resetForm();
            setSubmitting(false);
    };

    const handleSelectImage = (image) =>{
        setProductThumb(image);
        setShowMedia(false);
    }

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className="innerPageWrapper">
                <div className="container-fluid">
                    <div className="page-title">
                        <h2>
                            <BiCategory /> Edit Product
                        </h2>
                        <Link to="/app/products" className="btn btn-success">
                            <BiCategory /> Products
                        </Link>
                    </div>
                    <div className="card">
                        <div className="card-body">
                        {
              isLoading ? <div className='loader'>
                <img src='/images/loader.gif' alt='loader'/>
              </div> :
                <Formik
                enableReinitialize
                  initialValues={{
                      title: productData.title,
                      short_description: productData.short_description,
                      description:  productData.description,
                      specification:  productData.description,
                      regular_price:  productData.regular_price,
                      sale_price:  productData.sale_price,
                      tax_status:  productData.tax_status,
                      best_seller:  productData.best_seller,
                      sku:  productData.sku,
                      stock:  productData.stock,
                      product_image:  productData.product_thumbnail.id,
                      status:  productData.status,
                      categories: productData.categories,
                  }}
                  validate={(values) => {
                      const errors = {};
                      if (!values.title) {
                          errors.title = "Title is required";
                      }
                      if (!values.short_description) {
                          errors.short_description = "Short description is required";
                      }
                      if (!values.description) {
                          errors.description = "Description is required";
                      }
                      if (!values.specification) {
                          errors.specification = "Specification is required";
                      }
                      if (!values.regular_price) {
                          errors.regular_price = "Regular price is required";
                      }else if(isNaN(values.regular_price)){
                          errors.regular_price = "Regular price is number or decimal value";
                      }
                      if (!values.sale_price) {
                          errors.sale_price = "Sale price is required";
                      }else if(isNaN(values.sale_price)){
                          errors.sale_price = "Sale price is number or decimal value";
                      }

                      if (!values.sku) {
                          errors.sku = "SKU is required";
                      }
                      if (!values.stock) {
                          errors.stock = "Stock is required";
                      }
                    //   if (!values.product_image) {
                    //       errors.product_image = "Product image is required";
                    //   }

                      return errors;
                  }}
                  onSubmit={handleSubmit}
              >
                  {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue, // Add setFieldValue to handle file input
                  }) => (
                      <form onSubmit={handleSubmit}>
                          <div className="row">
                              <div className="col-md-8">
                                  <div className="form-group">
                                      <label>Product Title</label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          name="title"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.title}
                                      />
                                      {errors.title && touched.title && (
                                          <span className="help-error">{errors.title}</span>
                                      )}
                                  </div>

                                  <div className="form-group">
                                      <label>Short description</label>
                                      <Field
                                          name="short_description">
                                          {({ field, form }) => (
                                              <CKEditorField field={field} form={form} />
                                          )}
                                      </Field>


                                      {errors.short_description &&
                                          touched.short_description && (
                                              <span className="help-error">
                                                  {errors.short_description}
                                              </span>
                                          )}
                                  </div>
                                  <div className="form-group">
                                      <label>Description</label>
                                      <Field name="description">
                                          {({ field, form }) => (
                                              <CKEditorField field={field} form={form} />
                                          )}
                                      </Field>

                                      {errors.description && touched.description && (
                                          <span className="help-error">
                                              {errors.description}
                                          </span>
                                      )}
                                  </div>
                                  <div className="form-group">
                                      <label>Product Specification</label>
                                      <Field name="specification">
                                          {({ field, form }) => (
                                              <CKEditorField field={field} form={form} />
                                          )}
                                      </Field>
                                      {errors.specification && touched.specification && (
                                          <span className="help-error">
                                              {errors.specification}
                                          </span>
                                      )}
                                  </div>



                              </div>

                              <div className="col-md-4">
                                  <div className="form-group">
                                      <label>Product Thumbnail</label>
                                      <div className="dropzone-box">
                                                      {
                                                        product_thumb !== '' && <img src={product_thumb.path} alt="product_thumbnail"/>
                                                      }  
                                                       {
                                                        product_thumb !== '' ?  <button type="button" className="floatBtn" onClick={()=>setShowMedia(true)}><FiEdit size={15}/></button> : <button type="button" onClick={()=>setShowMedia(true)}>Choose your file</button>
                                                       }
                                                    </div>
                                   
                                     
                                      {errors.product_image && touched.product_image && (
                                          <span className="help-error">
                                              {errors.product_image}
                                          </span>
                                      )}
                                  </div>
                                  <div className="form-group row">
                                      <div className="col-md-6">
                                          <label>Regular Price</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              name="regular_price"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.regular_price}
                                              placeholder="0.00"
                                          />
                                          {errors.regular_price && touched.regular_price && (
                                              <span className="help-error">
                                                  {errors.regular_price}
                                              </span>
                                          )}
                                      </div>
                                      <div className="col-md-6">
                                          <label>Sale Price</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              name="sale_price"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.sale_price}
                                              placeholder="0.00"
                                          />
                                          {errors.sale_price && touched.sale_price && (
                                              <span className="help-error">
                                                  {errors.sale_price}
                                              </span>
                                          )}
                                      </div>
                                  </div>
                                

                                  <div className="form-group row">
                                      <div className="col-md-6">
                                          <label>SKU</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              name="sku"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.sku}
                                          />
                                          {errors.sku && touched.sku && (
                                              <span className="help-error">{errors.sku}</span>
                                          )}
                                      </div>
                                      <div className="col-md-6">
                                          <label>Total Stock</label>
                                          <input
                                              type="text"
                                              className="form-control"
                                              name="stock"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.stock}
                                          />
                                          {errors.stock && touched.stock && (
                                              <span className="help-error">{errors.stock}</span>
                                          )}
                                      </div>

                                  </div>
                                  <div className="form-group row">
                                      <div className="col-md-6">
                                          <label>Tax Status</label>
                                          <select className="form-control" name="tax_status" onChange={handleChange}
                                              onBlur={handleBlur} value={values.tax_status ? 1 : 0}>
                                              <option value={0}>No</option>
                                              <option value={1}>Yes</option>
                                          </select>
                                      </div>
                                      <div className="col-md-6">

                                          <div class="form-check-custom">
                                              <input type="checkbox" value={values.best_seller} id="best_sale" name="best_seller" onChange={handleChange}
                                                  onBlur={handleBlur} checked={values.best_seller}/>
                                              <label htmlFor="best_sale">
                                                  Best Saller
                                              </label>
                                          </div>
                                      </div>
                                  </div>

                                  <div className="form-group">
                                      <label>Categories</label>
                                      <Field name="categories">
                                          {({ field }) => (
                                              <Select
                                                  {...field}
                                                  value={categoryOptions.filter((option) =>
                                                      values.categories.includes(option.value)
                                                  )}
                                                  onChange={(selectedOptions) =>
                                                      setFieldValue(
                                                          "categories",
                                                          selectedOptions.map(
                                                              (option) => option.value
                                                          )
                                                      )
                                                  }
                                                  options={categoryOptions}
                                                  placeholder="Select categories"
                                                  isMulti
                                                  className="select2"
                                              />
                                          )}
                                      </Field>
                                  </div>

                                  <div className="form-group">
                                      <label>Status</label>
                                      <select className="form-control" name="status" onChange={handleChange}
                                          onBlur={handleBlur} value={values.status ? 1 : 0}>
                                          <option value={1}>Published</option>
                                          <option value={0}>Draft</option>
                                      </select>
                                  </div>

                                  <button
                                      type="submit"
                                      className="btn btn-primary btn-block"
                                      disabled={isSubmitting}
                                  >
                                      {isSubmitting ? "Updating..." : "Update Now"}
                                  </button>
                              </div>
                          </div>
                          {
                            showMedia && <MediaLibraryPopup show={showMedia} setShowMedia={setShowMedia} handleSelectImage={handleSelectImage} setFieldValue={setFieldValue} fieldName="product_image"/>
                          }
                      </form>
                  )}
              </Formik>
              }
                          
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditProduct;
