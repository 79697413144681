import React, { useEffect } from "react";
import "../assets/css/login.css";
import { FaUserAlt } from "react-icons/fa";
import { Formik, ErrorMessage } from "formik";
import { Link, useNavigate  } from "react-router-dom";
import { GetLogin, Alerts } from "../helpers";
import { useAuth } from "../context/AuthContext";
const Login = () => {
  const { isAuthenticated, login } = useAuth();
  const navigate = useNavigate();
  const handleSubmit = async (values, { setSubmitting }) => {
    let resp = await GetLogin.getUserLogin(values);
    if (resp) {
      if (resp.status) {
        const roles = resp.userData.roles;
        let isSuperAdmin = roles.find((o) => o === "ROLE_SUPER_ADMIN");
        if (isSuperAdmin !== undefined) {
            login(resp);
            navigate('/');
          
        } else {
          Alerts.alertMessage("Authorization failed!", "top-center", "error");
        }
      } else {
        Alerts.alertMessage(resp.message, "top-center", "error");
      }
    }
  };
  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="authWrapper">
      <div className="authForm">
        <div className="authFormHeader">
          <div className="authFormHeaderIcon">
            <FaUserAlt />
          </div>

          <h1>Sign In</h1>
        </div>
        <div className="authFormBody">
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Username is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your username"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <ErrorMessage
                    className="help-error"
                    name="email"
                    component="div"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <ErrorMessage
                    className="help-error"
                    name="password"
                    component="div"
                  />
                </div>

                <button
                  type="submit"
                  className="t_primary_btn t_full_width "
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Please wait.." : "Sign in"}
                </button>
              </form>
            )}
          </Formik>
          <div className="forget_link">
            <Link to="/">Forget Password</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
