import api from '../../ApiConfig';
import { Apis } from '../../config';
var token = localStorage.getItem('userToken');
const getAllCategories = async () => {
  try {
    let result = await api.get(Apis.GetAllCategories, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const createCategory = async (data) => {
  try {
    let result = await api.post(Apis.CreateCategory, data, {
        headers: {
          'x-access-token': token,
          // "Content-Type": "multipart/form-data",
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const updateCategory = async (data, id) => {
  try {
    let result = await api.put(Apis.UpdateCategory+id, data, {
        headers: {
          'x-access-token': token
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const deleteCategory = async (id) => {
  try {
    let result = await api.delete(Apis.DeleteCategory+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const getSingleCategory = async (id) => {
  try {
    let result = await api.get(Apis.SingleCategory+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const getCategoryFaqs = async (id) => {
  try {
    let result = await api.get(Apis.GetCategoryFaqs+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const createCategoryFaq = async (data, id) => {
  try {
    let result = await api.post(Apis.CreateCategoryFaq+id, data, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const deleteCategoryFq = async (id) => {
  try {
    let result = await api.delete(Apis.DeleteCategoryFaq+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const updateCategoryFaq = async (data, id) => {
  try {
    let result = await api.put(Apis.UpdateCategoryFaq+id, data, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const createVariation = async (data) => {
  try {
    let result = await api.post(Apis.CreateVariation, data, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const updateVariationValue = async (data, id) => {
  try {
    let result = await api.put(Apis.UpdateVariationData+id, data, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const createCategoryVariation = async (data) => {
  try {
    let result = await api.post(Apis.CreateProductCategoryVariation, data, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const getVariations = async () => {
  try {
    let result = await api.get(Apis.GetVariations, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const getCategoryVariations = async () => {
  try {
    let result = await api.get(Apis.GetCategoryVariations, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const getSingleCategoryVariations = async (id) => {
  try {
    let result = await api.get(Apis.GetCategoryWiseVariations+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const getSingleVariation = async (id) => {
  try {
    let result = await api.get(Apis.GetSingleVariation + id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const getAllVariationOptions = async (id) => {
  try {
    let result = await api.get(Apis.GetAllVariationOptions, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const updateVariation = async (id, data) => {
  try {
    let result = await api.put(Apis.UpdateVariation + id, data,{
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const deleteVariation = async (id) => {
  try {
    let result = await api.delete(Apis.DeleteVariation+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const deleteCategoryVariation = async (id) => {
  try {
    let result = await api.delete(Apis.DeleteCategoryVariation+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};


const createVariationOption = async (data) => {
  try {
    let result = await api.post(Apis.CreateVariationOption, data, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const deleteVariationOption = async (id) => {
  try {
    let result = await api.delete(Apis.DeleteVariationOption+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};

const getProductVariations = async (id) => {
  try {
    let result = await api.get(Apis.GetProductVariations+id, {
        headers: {
          'x-access-token': token,
        }
      });
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const exportedObject = {
    getAllCategories,
    createCategory,
    deleteCategory,
    getSingleCategory,
    updateCategory,
    getCategoryFaqs,
    createCategoryFaq,
    deleteCategoryFq,
    updateCategoryFaq,
    createVariation,
    getCategoryVariations,
    getSingleVariation,
    updateVariation,
    deleteVariation,
    createVariationOption,
    deleteVariationOption,
    getAllVariationOptions,
    getProductVariations,
    getSingleCategoryVariations,
    getVariations,
    createCategoryVariation,
    deleteCategoryVariation,
    updateVariationValue
};
export default exportedObject;