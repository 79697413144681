
import Modal from 'react-bootstrap/Modal';

const MyModal = ({children, show, setAddNew, title}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={()=>setAddNew(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         {title}
        </Modal.Title>
       </Modal.Header>
        <Modal.Body className='pd-50'>
            {children}
        </Modal.Body>
     
      </Modal>
    </>
  );
}

export default MyModal;