
import Modal from 'react-bootstrap/Modal';

const AddProductGallery = ({children, show, handleClose, title="AddProductSetting"}) => {

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
       </Modal.Header>
        <Modal.Body className='pd-50 pt-3'>
            {children}
        </Modal.Body>
     
      </Modal>
    </>
  );
}

export default AddProductGallery;