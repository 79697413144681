
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaTimes } from "react-icons/fa";
const ConfModal = ({message, delId, handleDelete}) => {
    const [isShow, setShow] = useState(false);

    const handleDeleteItem = (id) =>{
        handleDelete(id);
        setShow(false);
    }
  return (
    <>
      <button className='btn btn-danger btn-sm' onClick={()=> setShow(true)}><FaTimes size={14}/></button>
      <Modal
        show={isShow}
        onHide={()=>setShow(false)}
        backdrop="static"
        keyboard={false}
      >
     
        <Modal.Body className='text-center pd-50'>
            {message}
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <button className='btn btn-danger w-100' onClick={()=>setShow(false)}>
            No
          </button>
          <button className='btn btn-primary w-100' onClick={()=>handleDeleteItem(delId)}>Yes</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfModal;