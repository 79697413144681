import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../../../components/partials/Breadcrumb';
import { BiCategory } from 'react-icons/bi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Alerts, GetPost } from '../../../../helpers';
import MediaLibraryPopup from '../../../../components/cmp/MediaLibraryPopup';
import { FiEdit } from "react-icons/fi";
const breadcrumbs = [
  {
    id: 1,
    label: 'Dashboard',
    link: '/',
  },
  {
    id: 2,
    label: 'Categories',
    link: '/app/product/categories',
  },
  {
    id: 3,
    label: 'Update',
    link: '/app/product/categories/edit',
  },
];

const EditPostCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState('');
  const [showMedia, setShowMedia] = useState(false);
  const [imageFile, setImageFile] = useState('');

  const getCategory = async (id) =>{
    const resp = await GetPost.getSinglePostCategory(id);
    if(resp && resp.status){
        setCategory(resp.data);
        setImageFile(resp.data.category_thumbnail);
       
    }else{
        Alerts.alertMessage(resp.message, "top-right", "success");
        navigate('/app/post/categories/');
    }
  }

  useEffect(() => {
    getCategory(id);
  }, [id]);

  
  const handleSubmit = async (values) => {
    const data = {
      title:values.name,
      description:values.description,
      thumbnail:values.cat_thumb,
      status:parseInt(values.status),
    }
    let resp = await GetPost.updatePostCategory(data, id);
    if(resp && resp.status){
      Alerts.alertMessage(resp.message, "top-right", "success");
      navigate('/app/post/categories/');
    }else{
      Alerts.alertMessage(resp.message, "top-right", "error");
    }

  };

  const handleSelectImage = (image) => {
    setImageFile(image);
    setShowMedia(false);
  }
  
  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="innerPageWrapper">
        <div className="container-fluid">
          <div className="page-title">
            <h2>
              <BiCategory /> Edit Post Categories
            </h2>
            <Link to="/app/post/categories/" className="btn btn-success">
              <BiCategory /> Post Categories
            </Link>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                enableReinitialize={true}
                initialValues={{ name: category.title, description: category.description , cat_thumb: category.category_thumbnail?.id, status:category.is_active }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = 'Category name is required';
                  }
                  if (!values.description) {
                    errors.description = 'Category description is required';
                  }
              

                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue, // Add setFieldValue to handle file input
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {errors.name && touched.name && <span className="help-error">{errors.name}</span>}
                        </div>
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            className="form-control"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                          />
                          {errors.description && touched.description && (
                            <span className="help-error">{errors.description}</span>
                          )}
                        </div>

                        <div className="form-group">
                          <label>Status</label>
                          <select
                            className="form-control"
                            name="status"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.status}
                          >
                            <option value={1}>Active</option>
                            <option value={0}>Draft</option>
                           
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                      <div className="form-group">
                          <label>Category Thumbnail</label>
                          <div className="dropzone-box">
                            {
                              imageFile !== '' && <img src={imageFile.path} alt="product_thumbnail" />
                            }
                            {
                              imageFile !== '' ? <button type="button" className="floatBtn" onClick={() => setShowMedia(true)}><FiEdit size={15} /></button> : <>
                                <p>Drop files to upload</p>
                                <p>or</p>
                                <button type="button" onClick={() => setShowMedia(true)} className="choose-btn">Choose file</button>
                              </>
                            }
                          </div>
                          {errors.cat_thumb && touched.cat_thumb && (
                            <span className="help-error text-center">{errors.cat_thumb}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    {
                      showMedia && <MediaLibraryPopup show={showMedia} setShowMedia={setShowMedia} handleSelectImage={handleSelectImage} setFieldValue={setFieldValue} fieldName="cat_thumb" />
                    }
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPostCategory;
